import { Alert, Button, Checkbox, CloseButton, Divider, Flex, Grid, Input, LoadingOverlay, Modal, Text } from "@mantine/core";
import { IconCheck, IconChevronsRight } from "@tabler/icons-react";
import React, { useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import * as yup from "yup";
import { BusinessApi, GeneralApi } from "../../apis";
import { IAuth } from "../../interfaces/IAuth";
import { IGeneral } from "../../interfaces/IGeneral";
import { initGeneral } from "../../store/General";
import { message } from "../../utils/message";
import { Form } from "../form";
import { DatePickerField } from "../form/datepicker-field";
import { MultiSelectField } from "../form/multi-select-field";
import { RadioGroupField } from "../form/radiogroup-field";
import { SelectField } from "../form/select-field";
import { SwitchField } from "../form/switch-field";
import { TextField } from "../form/text-field";
import { PageLayout } from "../layout";
import { BusinessSectorForm } from "./business-sector-form";

type IFormData = {
  type: string | undefined | null;
  businessSectorId: string | undefined;
  businessSubSectorId: string | undefined | null;
  purchaseType: string | undefined;
  productCategoryType: string | undefined;
  productCategoryIds: string[] | undefined;
  serviceCategoryType: string | undefined;
  serviceCategoryIds: string[] | undefined;
  isVatPayer: boolean | undefined;
};

const schema = yup.object({
  type: yup.string().nullable(),
  businessSectorId: yup.string().required("Заавал бөглөнө!").nullable(),
  businessSubSectorId: yup.string().required("Заавал бөглөнө!").nullable(),
  purchaseType: yup.string().required("Заавал бөглөнө!"),
  productCategoryType: yup
    .string()
    .when("purchaseType", (purchaseType, field) =>
      typeof purchaseType === "string" && purchaseType !== "SERVICE" ? field.required("Заавал бөглөнө!") : field,
    ),
  productCategoryIds: yup
    .array()
    .when("productCategoryType", (productCategoryType, field) =>
      typeof productCategoryType === "string" && productCategoryType === "CHOOSE" ? field.required("Заавал бөглөнө!") : field,
    ),
  serviceCategoryType: yup
    .string()
    .when("purchaseType", (purchaseType, field) => (typeof purchaseType === "string" && purchaseType !== "GOODS" ? field.required("Заавал бөглөнө!") : field)),
  serviceCategoryIds: yup
    .array()
    .when("serviceCategoryType", (serviceCategoryType, field) =>
      typeof serviceCategoryType === "string" && serviceCategoryType === "CHOOSE" ? field.required("Заавал бөглөнө!") : field,
    ),
  isVatPayer: yup.boolean().required("Заавал бөглөнө!"),
});

export function SupplierInfoForm({
  setActiveTab,
  actionOutside,
  reload,
  onClose,
  type,
  setId,
}: {
  setActiveTab: any;
  actionOutside?: any;
  reload: () => void;
  onClose: () => void;
  type: string;
  setId: any;
}) {
  const [loading, setLoading] = React.useState<boolean>(false);
  const [action, setAction] = React.useState<any>([]);
  const { user } = useSelector((state: { auth: IAuth }) => state.auth);
  const { productCategories, serviceCategories, businessSectors, legalEntityTypes } = useSelector((state: { general: IGeneral }) => state.general);
  const refForm = useRef<any>(null);
  const dispatch = useDispatch();

  const businessSector = businessSectors?.find((sector: any) => sector.refCode === "BSG-101804");

  const [initialData] = React.useState<IFormData>(() => {
    return {
      type: type,
      businessSectorId: businessSector ? businessSector.id : undefined,
      businessSubSectorId: businessSectors?.find((c: any) => c?.parentId === businessSector.id).id,
      isDefault: false,
      purchaseType: "BOTH",
      productCategoryType: "LATER",
      productCategoryIds: [],
      serviceCategoryType: "LATER",
      serviceCategoryIds: [],
      isVatPayer: false,
      createdAt: new Date(),
      ...(actionOutside[1] && {
        ...actionOutside[1],
        regUser: `${actionOutside[1]?.regUser?.lastName ? actionOutside[1]?.regUser?.lastName[0] + ". " : ""} ${actionOutside[1]?.regUser?.firstName || "-"}`,
      }),
    };
  });

  const onSubmit = async (values: IFormData | any, clickType: "next" | "close") => {
    setLoading(true);

    try {
      if (actionOutside[1]) {
        await BusinessApi.update(actionOutside[1].id, {
          businessSectorId: values.businessSectorId,
          businessSubSectorId: values.businessSubSectorId,
          purchaseType: values.purchaseType,
          productCategoryType: values.productCategoryType,
          productCategoryIds: values.productCategoryIds,
          serviceCategoryType: values.serviceCategoryType,
          serviceCategoryIds: values?.serviceCategoryIds,
          isVatPayer: values.isVatPayer || false,
        });
        message.success("Таны хүсэлт амжилттай!");
      } else {
        let res: any = await BusinessApi.create({
          businessSectorId: values.businessSectorId,
          businessSubSectorId: values.businessSubSectorId,
          purchaseType: values.purchaseType,
          productCategoryType: values.productCategoryType,
          productCategoryIds: values.productCategoryIds,
          serviceCategoryType: values.serviceCategoryType,
          serviceCategoryIds: values?.serviceCategoryIds,
          type: values.type,
          isVatPayer: values.isVatPayer || false,
        });
        message.success("Таны хүсэлт амжилттай!");
        setId(res.id);
      }
      if (clickType === "close") {
        onClose();
      } else {
        setActiveTab("marketing");
      }

      const res = await GeneralApi.init();
      dispatch(initGeneral(res));
    } catch (error: any) {
      message.error(error?.message!);
    }

    reload();
    setLoading(false);
  };

  const onCancel = () => {
    setAction([]);
  };

  return (
    <>
      <Form ref={refForm} initialValues={initialData} validationSchema={schema}>
        {({ values, setFieldValue }) => {
          return (
            <>
              <Divider my="md" color="gray.5" />
              <Grid sx={{ position: "relative" }} gutter="sm">
                <LoadingOverlay visible={loading} />
                <Grid.Col span={{ xs: 12, sm: 6, md: 4 }}>
                  <TextField
                    disabled
                    nullable
                    name="refCode"
                    label={type === "BUYER" ? "Buyer бизнес Ref#" : "Supplier бизнес Ref#"}
                    placeholder={type === "BUYER" ? "Buyer бизнес Ref# (Авто үүснэ) " : "Supplier бизнес Ref# (Авто үүснэ)"}
                  />
                </Grid.Col>
                <Grid.Col span={{ xs: 12, sm: 6, md: 4 }}>
                  <DatePickerField disabled nullable name="createdAt" label="Бүртгэсэн огноо, цаг" placeholder="Авто үүснэ" />
                </Grid.Col>
                <Grid.Col span={{ xs: 12, sm: 6, md: 4 }}>
                  <TextField disabled nullable name="regUser" label="Бүртгэсэн ажилтан" placeholder="Авто үүснэ" required />
                </Grid.Col>
                <Grid.Col span={12}>
                  <Divider
                    color="gray"
                    styles={{ label: { fontSize: 14, fontWeight: 500 } }}
                    my="xs"
                    label={type === "BUYER" ? "ХУДАЛДАН АВАЛТЫН МЭДЭЭЛЭЛ" : "НИЙЛҮҮЛЭЛТИЙН МЭДЭЭЛЭЛ"}
                  />
                </Grid.Col>
                <Grid.Col span={{ xs: 12, sm: 6, md: 4 }}>
                  <SelectField
                    onChange={() => setFieldValue("businessSubSectorId", undefined)}
                    options={businessSectors?.filter((c: any) => !c.parentId)?.map((c: any) => ({ label: c.name, value: c.id }))}
                    name="businessSectorId"
                    label={type === "BUYER" ? "Худалдан авалт хийх сектор" : "Нийлүүлэлт хийх сектор"}
                    placeholder={type === "BUYER" ? "Худалдан авалт хийх сектор" : "Нийлүүлэлт хийх сектор"}
                  />
                </Grid.Col>
                <Grid.Col span={{ xs: 12, sm: 6, md: 4 }}>
                  <SelectField
                    disabled={!values.businessSectorId}
                    options={businessSectors?.filter((c: any) => c?.parentId === values.businessSectorId)?.map((d: any) => ({ label: d.name, value: d.id }))}
                    name="businessSubSectorId"
                    label="Дэд сектор"
                    placeholder="Дэд сектор"
                  />
                </Grid.Col>
                <Grid.Col span={{ xs: 12, sm: 6, md: 4 }}>
                  <Input.Wrapper label="Үндсэн эсэх">
                    <div>
                      <Flex gap={8} mt={"8px"}>
                        <Checkbox readOnly checked={values?.isDefault || false} />
                        <Text size={"sm"}>Тийм</Text>
                      </Flex>
                    </div>
                  </Input.Wrapper>
                </Grid.Col>
                <Grid.Col span={{ xs: 12, sm: 6, md: 4 }}>
                  <RadioGroupField
                    name="purchaseType"
                    options={[
                      { value: "GOODS", label: "Бараа, бүтээгдэхүүн" },
                      { value: "SERVICE", label: "Ажил үйлчилгээ" },
                      { value: "BOTH", label: "Аль аль нь" },
                    ]}
                    label={type === "BUYER" ? "Худалдан авалтын төрөл" : "Нийлүүлэлтийн төрөл"}
                    defaultValue="BOTH"
                  />
                </Grid.Col>
                {values.purchaseType === "SERVICE" || values.purchaseType === undefined ? null : (
                  <Grid.Col span={{ xs: 12, sm: 6, md: 4 }}>
                    <RadioGroupField
                      name="productCategoryType"
                      options={[
                        { value: "CHOOSE", label: "Барааны категори сонгох" },
                        { value: "ALL", label: "Бүх категори" },
                        { value: "LATER", label: "Захиалах үедээ сонгоно" },
                      ]}
                      label="Бараа, бүтээгдэхүүний категори"
                      defaultValue="ALL"
                    />
                  </Grid.Col>
                )}

                {values.purchaseType === "GOODS" || values.purchaseType === undefined ? null : (
                  <Grid.Col span={{ xs: 12, sm: 6, md: 4 }}>
                    <RadioGroupField
                      name="serviceCategoryType"
                      options={[
                        { value: "CHOOSE", label: "Ажил, үйлчилгээний категори сонгох" },
                        { value: "ALL", label: "Бүх категори" },
                        { value: "LATER", label: "Ажил бүртгэх үедээ сонгоно" },
                      ]}
                      label="Ажил үйлчилгээний категори"
                    />
                  </Grid.Col>
                )}

                {values.productCategoryType === "CHOOSE" && values.purchaseType !== "SERVICE" ? (
                  <Grid.Col span={{ xs: 12, sm: 6, md: 4 }}>
                    <MultiSelectField
                      options={productCategories?.map((c: any) => ({ label: c.name, value: c.id }))}
                      name="productCategoryIds"
                      label="Барааны категори"
                      placeholder="Барааны категори"
                    />
                  </Grid.Col>
                ) : null}

                {values.serviceCategoryType === "CHOOSE" && values.purchaseType !== "GOODS" ? (
                  <Grid.Col span={{ xs: 12, sm: 6, md: 4 }}>
                    <MultiSelectField
                      options={serviceCategories?.map((c: any) => ({ label: c.name, value: c.id }))}
                      name="serviceCategoryIds"
                      label="Үйлчилгээний категори"
                      placeholder="Үйлчилгээний категори"
                    />
                  </Grid.Col>
                ) : null}

                <Grid.Col span={12}>
                  <Divider color="gray" styles={{ label: { fontSize: 14, fontWeight: 500 } }} my="xs" label="ГЭРЭЭНИЙ ХАРИЛЦААНЫ МЭДЭЭЛЭЛ" />
                </Grid.Col>

                <Grid.Col span={12}>
                  <Alert styles={{ message: { color: "#44566c", fontWeight: 500 } }} radius="md" withCloseButton={false} title="Таны хянах мэдээлэл">
                    Та DeHUB Network-д бараа, бүтээгдэхүүн, ажил үйлчилгээ захиалах үед захиалгын баримт, нэхэмжлэх, төлбөр тооцооны баримт, НӨАТ баримт гэх мэт
                    B2B харилцаанд ашиглагдах мэдээллээ сайтар нягтлаад баталгаажуулна уу. Хэрэв буруу байвал Компанийн үндсэн мэдээллээ өөрчлөн үү.
                  </Alert>
                </Grid.Col>

                <Grid.Col span={{ xs: 12, sm: 6, md: 4 }}>
                  <Flex direction="column" gap={10}>
                    <Text fz={14} fw={500} c="#212529">
                      Аж ахуйн нэгжийн нэр /Монгол хэл/ <span style={{ color: "red", marginLeft: 2, fontSize: 14 }}>*</span>
                    </Text>
                    <Text fz={14} fw={700}>
                      {user?.partner?.businessName || "-"}
                    </Text>
                  </Flex>
                </Grid.Col>
                <Grid.Col span={{ xs: 12, sm: 6, md: 4 }}>
                  <Flex direction="column" gap={10}>
                    <Text fz={14} fw={500} c="#212529">
                      Аж ахуйн нэгжийн хэлбэр <span style={{ color: "red", marginLeft: 2, fontSize: 14 }}>*</span>
                    </Text>
                    <Text fz={14} fw={700}>
                      {legalEntityTypes?.find((item: any) => item.code === user?.partner?.legalEntityType)?.name ?? "-"}
                    </Text>
                  </Flex>
                </Grid.Col>
                <Grid.Col span={{ xs: 12, sm: 6, md: 4 }}>
                  <Input.Wrapper label="НӨАТ төлөгч эсэх" required>
                    <SwitchField name="isVatPayer" />
                  </Input.Wrapper>
                </Grid.Col>
                <Grid.Col span={{ xs: 12, sm: 6, md: 4 }}>
                  <Flex direction="column" gap={10}>
                    <Text fz={14} fw={500} c="#212529">
                      Регистрийн дугаар <span style={{ color: "red", marginLeft: 2, fontSize: 14 }}>*</span>
                    </Text>
                    <Text fz={14} fw={700}>
                      {user?.partner?.regNumber ?? "-"}
                    </Text>
                  </Flex>
                </Grid.Col>
                <Grid.Col span={{ xs: 12, sm: 6, md: 4 }}>
                  <Flex direction="column" gap={10}>
                    <Text fz={14} fw={500} c="#212529">
                      Утасны дугаар <span style={{ color: "red", marginLeft: 2, fontSize: 14 }}>*</span>
                    </Text>
                    <Text fz={14} fw={700}>
                      {user?.partner?.phone ?? "-"}
                    </Text>
                  </Flex>
                </Grid.Col>
                <Grid.Col span={{ xs: 12, sm: 6, md: 4 }}>
                  <Flex direction="column" gap={10}>
                    <Text fz={14} fw={500} c="#212529">
                      Утасны дугаар 2 <span style={{ color: "red", marginLeft: 2, fontSize: 14 }}>*</span>
                    </Text>
                    <Text fz={14} fw={700}>
                      {user?.partner?.phone2 ?? "-"}
                    </Text>
                  </Flex>
                </Grid.Col>
                <Grid.Col span={{ xs: 12, sm: 6, md: 4 }}>
                  <Flex direction="column" gap={10}>
                    <Text fz={14} fw={500} c="#212529">
                      И-мэйл хаяг <span style={{ color: "red", marginLeft: 2, fontSize: 14 }}>*</span>
                    </Text>
                    <Text fz={14} fw={700}>
                      {user?.partner?.email ?? "-"}
                    </Text>
                  </Flex>
                </Grid.Col>
                <Grid.Col span={{ xs: 12, sm: 6, md: 4 }}>
                  <Flex direction="column" gap={10}>
                    <Text fz={14} fw={500} c="#212529">
                      И-мэйл хаяг 2 <span style={{ color: "red", marginLeft: 2, fontSize: 14 }}>*</span>
                    </Text>
                    <Text fz={14} fw={700}>
                      {user?.partner?.email2 ?? "-"}
                    </Text>
                  </Flex>
                </Grid.Col>
                <Grid.Col span={12}>
                  <Divider />
                </Grid.Col>
                <Grid.Col span={12}>
                  <Flex direction="row" justify="space-between" align="center">
                    <Button
                      onClick={async () => {
                        let res = await refForm.current.submit();
                        if (res) {
                          onSubmit(values, "close");
                        }
                      }}
                      leftSection={<IconCheck />}>
                      Хадгалах
                    </Button>
                    <Button
                      onClick={async () => {
                        let res = await refForm.current.submit();
                        if (res) {
                          onSubmit(values, "next");
                        }
                      }}
                      variant="outline"
                      leftSection={<IconChevronsRight />}>
                      Үргэлжлүүлэх
                    </Button>
                  </Flex>
                </Grid.Col>
              </Grid>
            </>
          );
        }}
      </Form>
      <Modal opened={action[0] === "business_sector"} onClose={() => onCancel()} centered withCloseButton={false}>
        <PageLayout title={action[1] ? "Дэд сектор нэмэх" : "Сектор нэмэх"} extra={[<CloseButton onClick={() => onCancel()} key={1} />]}>
          <BusinessSectorForm sectors={businessSectors} action={action} onClose={() => onCancel()} />
        </PageLayout>
      </Modal>
    </>
  );
}
