import { ActionIcon, AppShell, Avatar, Box, Burger, Card, Flex, Group, MantineTheme, Menu, Popover, SimpleGrid, Text, UnstyledButton } from "@mantine/core";
import { createStyles } from "@mantine/emotion";
import { IconChevronDown, IconFileText, IconGridDots, IconLogout, IconNetwork, IconNotebook } from "@tabler/icons-react";
import { useSelector } from "react-redux";
import { LogoutApi } from "../../apis";
import { ReactComponent as EraLogo } from "../../assets/logo.svg";
import { IAuth } from "../../interfaces/IAuth";
import { message } from "../../utils/message";
import { useScreenDetector } from "../screen-detector";

export const Header = ({ opened, setOpened }: { opened: boolean; setOpened: (e: boolean) => void }) => {
  const { classes, theme } = useStyles();
  const { user, accessToken } = useSelector((state: { auth: IAuth }) => state.auth);
  const { isMobile, isTablet } = useScreenDetector();

  const logout = async () => {
    try {
      await LogoutApi.logout();
      window.location.href = "https://cp.dehub.mn/login";
    } catch (error) {
      message.error("");
    }
  };

  const mockdata = [
    { title: "Partner", icon: IconNetwork, color: "teal", link: `https://partner.dehub.mn/access?token=${accessToken}` },
    { title: "User", icon: IconFileText, color: "indigo", link: `https://user.dehub.mn/access?token=${accessToken}` },
  ];

  const items = mockdata.map((item) => (
    <UnstyledButton component="a" key={item.title} className={classes.item} href={item.link} bg={item.color + ".0"}>
      <item.icon color={theme.colors[item.color][6]} size="2rem" />
      <Text size="xs" mt={7}>
        {item.title}
      </Text>
    </UnstyledButton>
  ));

  return (
    <AppShell.Header>
      <Group className={classes.header}>
        {!isMobile && !isTablet ? (
          <Flex align={"center"} gap={10}>
            <a href="https://cp.dehub.mn" className={classes.logoBox}>
              <EraLogo className={classes.logo} />
              <span>
                <div className={classes.logoText}>DeHUB</div>
                <div className={classes.logoDesc}>платформ</div>
              </span>
            </a>
            <Box h={"32px"} style={{ borderRight: "1px solid #e5e5e5" }} />
            <Popover width={260} position="bottom" withArrow shadow="md">
              <Popover.Target>
                <ActionIcon size="lg" variant="transparent">
                  <IconGridDots size={"2.0rem"} stroke={1} />
                </ActionIcon>
              </Popover.Target>
              <Popover.Dropdown p={0}>
                <Card p={"sm"}>
                  <SimpleGrid cols={2}>{items}</SimpleGrid>
                </Card>
              </Popover.Dropdown>
            </Popover>
            <Text className={classes.moduleText}>Партнер удирдлага</Text>
          </Flex>
        ) : (
          <div>
            <Flex p={"xs"}>
              <Burger color="gray" size={"sm"} opened={opened} onClick={() => setOpened(!opened)} />
            </Flex>
          </div>
        )}

        <Menu width={260} position="bottom-end" transitionProps={{ duration: 150, exitDuration: 150, transition: "pop-top-right" }}>
          <Menu.Target>
            <UnstyledButton className={classes.user}>
              <Group>
                <Avatar src={user?.avatar} radius="xl" />

                <div style={{ flex: 1 }}>
                  <Text size="sm" fw={500} tt={"capitalize"}>
                    {user?.lastName ? user?.lastName[0] + "." : ""}
                    {user?.firstName || "-"}
                  </Text>
                  <Text c="dimmed" size="xs">
                    {user?.email ?? "-"}
                  </Text>
                </div>

                <IconChevronDown size={14} stroke={1.5} />
              </Group>
            </UnstyledButton>
          </Menu.Target>

          <Menu.Dropdown>
            <Menu.Item
              onClick={() => {
                window.location.href = "https://cp.dehub.mn/auth/profile/dan";
              }}
              leftSection={<IconNotebook size={20} color={theme.colors.indigo[6]} stroke={1.5} />}>
              Миний мэдээлэл
            </Menu.Item>
            <Menu.Divider />
            <Menu.Item onClick={() => logout()} color="red" leftSection={<IconLogout size={20} stroke={1.5} />}>
              Гарах
            </Menu.Item>
          </Menu.Dropdown>
        </Menu>
      </Group>
    </AppShell.Header>
  );
};

const useStyles = createStyles((theme: MantineTheme, _, u) => ({
  header: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    backgroundColor: theme.white,
    height: "100%",
    borderBottom: `4px solid #12b886`,
  },
  logoBox: {
    display: "flex",
    alignItems: "center",
    padding: "0 20px",
    gap: 10,
  },
  logo: {
    height: 32,
  },
  logoText: {
    fontSize: 20,
    fontWeight: 700,
    color: theme.colors.teal,
    lineHeight: "21px",
  },
  moduleText: {
    fontSize: 14,
    fontWeight: 700,
    color: theme.colors.teal,
    textTransform: "uppercase",
  },
  logoDesc: {
    fontSize: 10,
    fontWeight: 500,
    color: theme.colors.gray[6],
    textAlign: "end",
  },
  user: {
    padding: theme.spacing.xs,
    height: "100%",
    "&:hover": {
      backgroundColor: theme.colors.gray[2],
    },
  },
  userActive: {
    backgroundColor: u.dark === "dark" ? theme.colors.dark[8] : theme.white,
  },
  item: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    textAlign: "center",
    borderRadius: "8px",
    height: "90px",
    transition: "box-shadow 150ms ease, transform 100ms ease",
    ":hover": {
      boxShadow: theme.shadows.sm,
    },
  },
}));
